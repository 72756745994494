import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { isFeatureEnabled } from '@components/OptionalFeature/utils';
import PageContext from '@contexts/PageContext';
import { formatPostData } from '@services/postService';
import { HomePage } from '@containers';
import { apiClientProxy } from '@/http-client';

const Home = ({ data }) => (
  <PageContext.Provider value={data}>
    <HomePage />
  </PageContext.Provider>
);

export const getStaticProps = async ({ locale }) => {
  const LanguageId = locale == 'fr' ? 1 : 0;
  const { data: post } = await apiClientProxy.get(`/products/api/posts/byTitle/homepage`, { params: { LanguageId } });

  const data = formatPostData(post);

  if (!data) {
    return {
      notFound: true
    };
  }

  // Fix for build issues due to "undefined" display options
  if (typeof data?.DisplayOptions === 'undefined') {
    delete data['DisplayOptions'];
  }

  const checkTheme = isFeatureEnabled(data?.DisplayOptions, 'Header', 'DarkMode');
  data.DarkMode = checkTheme ? checkTheme : null;

  return {
    // Caches page and regenerates page in 60 seconds. Note: might be good to bump this number up when site goes live.
    revalidate: 60,
    props: {
      data,
      ...(await serverSideTranslations(locale, ['common', 'footer']))
    }
  };
};

export default Home;
